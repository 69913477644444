import React, { useEffect } from 'react';
import styled from 'styled-components';
import SEO from 'components/seo';
import { Link } from 'gatsby';
import InvestorsLayout from 'layouts/investors-layout';
import { media } from 'utils';

const StyledLink = styled(Link)`
  font-size: 2.571rem;
  font-weight: bold;
  color: ${({ theme })=> theme.color.green};
`;

const StyledUl = styled.ul`

  ${media.tablet`
    display: none;
  `}
`;

const StyledLi = styled.li`
  margin-bottom: 16px;
`;

const Investors = ()=> {
  useEffect(()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <>
      <SEO title="Inwestorzy" />
      <InvestorsLayout>
        <StyledUl>
          <StyledLi><StyledLink to="/investors/financial-documents">Dokumenty finansowe</StyledLink></StyledLi>
          <StyledLi><StyledLink to="/investors/corporate-governance">Ład korporacyjny</StyledLink></StyledLi>
          <StyledLi><StyledLink to="/investors/communication">Komunikacja z Akcjonariuszami</StyledLink></StyledLi>
        </StyledUl>
      </InvestorsLayout>
    </>
  );
};

export default Investors;
